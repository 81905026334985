import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { generalInformationModel, schedulingInformationModel, projectDetailsModel } from './GeneralInformation.constants';
import Validators from 'library/utilities/Validators';
import strings from 'resources/locales/Translate';
import { fetchFromStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
export const useGeneralInformation = (props) => {
  let zmccSystemCategoriesId = props.formValue.schedulingInformationForm?.formData?.zmccSystemCategoriesId;
  let stockId = {
    stockInformationId : props.formValue.generalInformationForm.formData.stockInformationId
  }
  const zmccCrmParams = fetchFromStorage(identifiers.zmccCrmParams);
  useEffect(() =>{
    if(props.formValue.generalInformationForm.formData && props.formValue.generalInformationForm.formData.stockInformationId){
      props.fetchPrimaryInstruments(stockId, zmccSystemCategoriesId);
      props.fetchZmccShippingSampleAddress(stockId.stockInformationId);
    }
  }, [stockId.stockInformationId]);



  const generalInfoModel = () => {
    const model = [...generalInformationModel];
    model[0].options = props.countries;
    model[1].options = props.loansIntended;
    model[2].options = props.usersList;
    return model;
  };
  const intendedIdsForOpportunityMandatory =[1,4,5,6,14];
  const projectDataModel = () => {
    const model = [...projectDetailsModel];
    if (((zmccCrmParams !== undefined && zmccCrmParams !== null && zmccCrmParams !== "") || props.formValue.projectDetailsForm?.formData?.crmTaskId) && !props.isZmcc) {
      model[0].required = true;
      // model[0].extraProps = {
      //   maxLength: 18,
      // };
      model[1].isDisplayed = false;
    }else {
      model[1].isDisplayed = true;
    }
    if (props.formValue.generalInformationForm.formData.intendedForId) {
      if (props.formValue.generalInformationForm.formData.intendedForId) {
        if (
          !intendedIdsForOpportunityMandatory.includes(props.formValue.generalInformationForm.formData.intendedForId)
        ) {
          if (props.user && props.user.crmSystemId === 'ngcrm') {
            model[0].required = false;
            model[0].validators = [
              {
                check: Validators.numberOfcharacters,
                message: strings.pleaseMindThatFieldNeedsToHave(18),
                strLength: 18,
              },
            ];
            model[0].extraProps = {
              maxLength: 18,
            };
          } else {
            model[0].required = false;
            model[0].validators = [
              { check: Validators.number, message: 'invalidNumberError' },
              {
                check: Validators.numberOfcharacters,
                message: strings.pleaseMindThatFieldNeedsToHave(9),
                strLength: 9,
              },
              { check: Validators.opportunityStartingWith200, message: 'correctOpportunityMessage' },
            ];
            model[0].extraProps = {
              maxLength: 9,
            };
          }
        } else {
          if (props.user && props.user.crmSystemId === 'ngcrm') {
            model[0].required = true;
            model[0].validators = [
              { check: Validators.required, message: 'requiredErrorMessage' },
              {
                check: Validators.numberOfcharacters,
                message: strings.pleaseMindThatFieldNeedsToHave(18),
                strLength: 18,
              },
            ];
            model[0].extraProps = {
              maxLength: 18,
            };
          } else {
            model[0].required = true;
            model[0].validators = [
              { check: Validators.required, message: 'requiredErrorMessage' },
              { check: Validators.number, message: 'invalidNumberError' },
              {
                check: Validators.numberOfcharacters,
                message: strings.pleaseMindThatFieldNeedsToHave(9),
                strLength: 9,
              },
              { check: Validators.opportunityStartingWith200, message: 'correctOpportunityMessage' },
            ];
            model[0].extraProps = {
              maxLength: 9,
            };
          }
        }
      }
    }
    return model;
  }

  const schedulingInfoModel = () => {
    const model = [...schedulingInformationModel];
    model[6].options = props.estimatedays;
    model[1].options = props.primaryInstrument;
    model[4].minDate = new Date();
    model[5].minDate = new Date();
    model[0].options = props.systemCategoriesList;
    if (props.primaryInstrument && props.primaryInstrument.length > 0 && props.formValue.schedulingInformationForm.formData.kitInformationId) {
      
      const filteredPrimaryInstruments= props.primaryInstrument.filter(item => item.kitInformationId != props.formValue.schedulingInformationForm.formData.kitInformationId)
      model[2].options = [...filteredPrimaryInstruments, {kitName: 'Other', kitInformationId: 0}];
    }
   
    model[7].options = props.dataWithin;

    if (props.formValue.schedulingInformationForm.formData.additionalInstrument && props.formValue.schedulingInformationForm.formData.additionalInstrument.includes(0)) {
      model[2].showExtraInfo = true;
      model[8].required = true;
      model[8].validators = [{ check: Validators.required, message: 'requiredErrorMessage' }]
    }else{
      model[2].showExtraInfo = false; 
      model[8].required = false; 
      model[8].validators = [];
    }
    if (props.formValue.generalInformationForm.formData.stockInformationId && props.formValue.schedulingInformationForm.formData.kitInformationId){
      model[3].disabled = false;
      if (props.formValue.schedulingInformationForm.formData.suggestedDate){
        model[4].disabled = false;
      }
    }
    
    let datesToDisable =[];
    if (props.primaryInstrument){
      if (props.formValue.schedulingInformationForm.formData && props.formValue.schedulingInformationForm.formData.kitInformationId){
        let kitData = props.primaryInstrument.find(item => item.kitInformationId === props.formValue.schedulingInformationForm.formData.kitInformationId);
        if (kitData && kitData.zmccCalendarBlocked && kitData.zmccCalendarBlocked.length >0){
          kitData.zmccCalendarBlocked.forEach(element => {
            let finalDate = new Date(element.from);
            datesToDisable.push(`${finalDate.getFullYear()}-${finalDate.getMonth()}-${finalDate.getDate()}`);
          });
        }
        else if (kitData && !kitData.zmccCalendarBlocked){
          model[3].disabled = true;
        }
      }
      model[3].tileDisabled = datesToDisable;
    }

    return model;
  };

  const handleForm = ({ values, field, formName }) => {
    if (field == 'stockInformationId'){
      props.fetchPrimaryInstruments(values, zmccSystemCategoriesId);
      props.formValue.schedulingInformationForm.formData.zmccSystemCategoriesId = '';
      props.formValue.schedulingInformationForm.formData.zmccSystemCategoriesIdFullValue = {};
      props.formValue.schedulingInformationForm.formData.kitInformationId = '';
      props.formValue.schedulingInformationForm.formData.kitInformationIdFullValue ={};
    }

    if (field == 'zmccSystemCategoriesId'){
      props.fetchPrimaryInstruments(stockId, values.zmccSystemCategoriesId);
      props.formValue.schedulingInformationForm.formData.kitInformationId = '';
      props.formValue.schedulingInformationForm.formData.kitInformationIdFullValue ={};
      props.formValue.schedulingInformationForm.formData.additionalInstrument = [];
      props.formValue.schedulingInformationForm.formData.additionalInstrumentFullValue =[];
      props.formValue.schedulingInformationForm.formData.suggestedDate = '';
      props.formValue.schedulingInformationForm.formData.alternateDate = '';
    }

    if (field == 'kitInformationId'){
      props.formValue.schedulingInformationForm.formData.suggestedDate = '';
      props.formValue.schedulingInformationForm.formData.alternateDate = '';
      props.formValue.schedulingInformationForm.formData.additionalInstrument = [];
      props.formValue.schedulingInformationForm.formData.additionalInstrumentFullValue =[];
    }

    props.updateSEMForm({
      [formName]: {
        ...props.formValue[formName],
        formData: {
          ...((props.formValue[formName] && props.formValue[formName].formData) || {}),
          ...values,
        },
      },
    });
    props.setUnsavedChanges(true);
  };

  return {
    handleForm,
    generalInfoModel,
    schedulingInfoModel,
    projectDataModel
  };
};
